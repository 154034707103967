.ql-toolbar{
    border-top-left-radius: 4px !important;
    border-top-right-radius: 4px !important;
}
.ql-container{
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}
.ql-editor p{
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;   
}