.divicon{    
    min-width: 150px !important;    
    width: auto !important;
    background: #fff;
    min-height: 20px !important;
    height: auto !important;
    max-height: 150px !important;
    overflow-x: hidden;
    overflow-y: auto;
    color:#555;
    font-size: 12px;
    line-height: 1;
    border: 1px solid #ccc;
    border-radius: 3px; 
    padding: 5px;
    text-align: center;
}